import './fastspin.css';

export const FastSpin = ()=>{
    return(
        <>


        <div>
            <div className='spinner'>

            </div>
            <p className='sam_abs' style={{color:'#blue', fontSize:'15.6px', wordSpacing:'1px',letterSpacing:'1px'}}>Setting up files now...</p>

        </div>


        </>
    )
};
